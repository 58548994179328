<template>
    <div class="view home">
        <Slider :slides="slides"/>
        <page-section :background="sectionBg">
            <h2>Welcome to Arctic Wolves</h2>
            <p>
                <br>
                Arctic Wolves is an open-world action game that blends the charm of wolves with dynamic combat set in a
                snowy, fantasy-inspired world. As a player, you’ll explore this winter landscape, meet various
                characters, and uncover their unique traits. Completing quests for these characters earns you currency,
                which you can use to buy weapons, apartments, and vehicles.
                <br>
                With a wide variety of locations to explore, you can roam the terrain by foot, soar in a helicopter, or
                drive an ATV. Customization is central to Arctic Wolves, giving you the ability to control your
                character’s appearance entirely, down to specific details. You can also design custom NPCs who can join
                you on your journeys.
                <br>
                <br>
                For those interested, the game includes optional NSFW content, featuring explicit character
                customization and interactive sex scenes.
                <br><br>
                Building out your personal spaces is also part of the experience; you can purchase furniture and decor
                to create your ideal apartment and upgrade your properties. Arctic Wolves invites players to create,
                explore, and battle in a unique world, where the freedom to customize and discover makes each adventure
                your own.
                <br>
                <br>
                <strong>
                    <span>Download Arctic Wolves here:</span>
                </strong>
                <br>
                <br>
                <a class="btn btn--large m-right" target="_blank" href="https://store.steampowered.com/app/3326280/Arctic_Wolves/">
                    Get it on Steam
                </a>
                <a class="btn btn--large m-right btn--alt-text" target="_blank" href="/dl/arctic_wolves_alpha_0.0.982.rar">
                    Windows
                </a>
                <a class="btn btn--large btn--alt-text" target="_blank" href="/dl/arctic_wolves_00982.zip">
                    Mac
                </a>
                <br>
                <br>
                You can also find Arctic Wolves on <a href="https://pup-blake.itch.io/arctic-wolves" target="_blank">itch.io</a>.
                <br>
            </p>

            <roadmap/>

            <video-player/>
        </page-section>
    </div>
</template>

<script>
import Slider      from '@/components/Slider';
import PageSection from '@/components/PageSection';
import Roadmap     from '@/components/Roadmap';
import VideoPlayer from '../components/VideoPlayer';

export default {
    name:       'Home',
    components: {
        VideoPlayer,
        Roadmap,
        PageSection,
        Slider,
    },
    data() {
        return {
            slides: [
                {
                    background:  require('@/assets/images/running.webp'),
                    title:       'Welcome to Arctic Wolves',
                    thumb_title: 'Welcome',
                    description: 'Arctic Wolves is an open-world action game that blends the charm of wolves with dynamic combat set in a snowy, fantasy-inspired world. As a player, you’ll explore this winter landscape, meet various characters, and uncover their unique traits.',
                },
                {
                    background:  require('@/assets/images/house.webp'),
                    title:       'Explore the Open World',
                    thumb_title: 'Exploration',
                    description: 'The world of Arctic Wolves is completely open and you can explore every inch within the map borders. With a wide variety of locations to explore, you can roam the terrain on foot, soar in a helicopter, drive an ATV, or even command a tank.',
                },
                {
                    background:  require('@/assets/images/dancing.webp'),
                    title:       'Activities & Quests',
                    thumb_title: 'Activities',
                    description: 'Besides the regular quests of the storyline, there will be activities to keep your player busy. A quad-bike race is planned, but also smaller side-jobs and possibly even more NSFW activities.',
                },
                {
                    background:  require('@/assets/images/customization.webp'),
                    title:       'Customize your Experience',
                    thumb_title: 'Customization',
                    description: 'Arctic Wolves gives you the ability to control your character’s appearance entirely, down to specific details and colors. NPCs can also be customized. The world is full of houses that you can buy and customize to create your own unique experience!',
                },
            ],
        };
    },
    computed: {
        sectionBg() {
            return require('@/assets/images/dancing.webp');
        },
    },
};
</script>
